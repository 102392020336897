/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
  font-family: 'metropolisbold';
    src: url('./Metropolis-Bold.woff2') format('woff2'),
         url('./Metropolis-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    letter-spacing: 3%;
}

.App {
  text-align: center;
  background-color: rgb(19,29,91);
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: 'metropolisbold', 'Montserrat', sans-serif;
}

body {
  padding: 0%;
  margin: 0;
}

nav {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid grey;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

nav h1 {
  margin: 0;
  font-family: 'metropolisbold', 'Montserrat', sans-serif;
  color: white;
  font-size: 45px;
}

.game {
  width: 100vw;
  height: calc(100vh - 170px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.board {
  width: 450px;
  height: 550px;
  border: 1px solid rgb(19,29,91);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.row {
  -webkit-box-flex: 33%;
      -ms-flex: 33%;
          flex: 33%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 5px
}

.letter {
  -webkit-box-flex: 33%;
      -ms-flex: 33%;
          flex: 33%;
  height: 100%;
  border: 1px solid grey;
  margin: 5px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  font-family: 'metropolisbold', 'Montserrat', sans-serif;
}

#correct {
  background-color: #528d4e
}

#almost {
  background-color: #b49f39;
}

#error {
  background-color: #3a393c;
}

.keyboard {
  width: 700px;
  height: 300px;
  margin-top: 60px;
}

.line1, .line2, .line3 {
  -webkit-box-flex: 33%;
      -ms-flex: 33%;
          flex: 33%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 5px;
}

.key {
  width: 50px;
  height: 70px;
  margin: 5px;
  border-radius: 4px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: grey;
  color: white;
  font-family: 'metropolisbold', 'Montserrat', sans-serif;
  cursor: pointer;
}

#big {
  width: 100px;
}

#disabled {
  background-color: #3a393c;
}

.share-results {
  padding: 6px;
  font-family: 'metropolisbold', 'Montserrat', sans-serif;
  
}



@media screen and (max-width: 800px) {

  .game {
    width: 100vw;
    height: calc(100vh - 170px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .board {
    width: 80%;
    height: 40%;
    border: 1px solid rgb(19,29,91);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .letter {
    -webkit-box-flex: 33%;
        -ms-flex: 33%;
            flex: 33%;
    height: 100%;
    border: 1px solid grey;
    margin: 5px;
    display: -ms-grid;
    display: grid;
    place-items: center;
    font-size: 22px;
    font-weight: bolder;
    color: white;
    font-family: 'metropolisbold', 'Montserrat', sans-serif;
  }

  .keyboard {
    width: 100vw;
    height: 300px;
    margin-top: 60px;
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .line1, .line2, .line3 {
    -webkit-box-flex: 33%;
        -ms-flex: 33%;
            flex: 33%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    /* justify-content: space-evenly; */
    margin: 5px;
  }
  

  .key {
    width: 30px;
    height: 70px;
    margin: 3px;
    border-radius: 4px;
    display: -ms-grid;
    display: grid;
    place-items: center;
    font-size: 14px;
    background-color: grey;
    color: white;
    font-family: 'metropolisbold', 'Montserrat', sans-serif;
    cursor: pointer;
  }

  #big {
    width: 45px;
    font-size: 12px;
  }
}